import React, {
  DOMAttributes,
  useRef,
  useState,
  forwardRef,
  InputHTMLAttributes,
  ButtonHTMLAttributes,
  ReactNode,
  ChangeEvent,
} from 'react';
import { AriaTextFieldProps, mergeProps, useButton, useFocusWithin } from 'react-aria';
import { PressEvents } from '@react-types/shared';
import { Country } from 'react-phone-number-input';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
import { useWindowSize } from 'usehooks-ts';
import { mul } from '@tensorflow/tfjs-core';
import styles from './Input.module.css';
import { Icon, IconType } from '../../Icon';
import { FlagIcon } from '../../FlagIcon';
import { Button } from '../../buttons';
import { Label } from '../../Label';

export interface InputProps extends AriaTextFieldProps {
  className?: string;
  inputFieldClassName?: string;
  size?: 'medium' | 'large';
  width?: number;
  flag?: ReactNode;
  iconLeft?: IconType;
  iconRight?: Country;
  textWithIconRight?: string;
  iconLeftClassName?: string;
  iconButtonClassName?: string | boolean;
  label?: string;
  labelClassName?: string;
  buttonText?: string;
  primaryButtonText?: string;
  copyButton?: boolean;
  buttonClassName?: string;
  buttonIcon?: IconType;
  isFilled?: string | boolean;
  errorMessage?: string | boolean;
  inputClassName?: string;
  placeholder?: string;
  onButtonPress?: PressEvents['onPress'];
  inputExternalProps?: InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;
  buttonExternalProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  customValue?: string;
  mask?: string;
  maskPlaceholder?: string;
  checkLabel?: boolean;
  checkMessage?: string | boolean;
  buttonLoading?: boolean;
  multiline?: boolean;
  isMessageAbsolute?: boolean;
  min?: number;
  max?: number;
  isDisabled?: boolean;
  themeColor?: string;
  borderRadius?: string;
  secondButtonIcon?: React.ReactNode;
  isFocusedStylesDisabled?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      inputFieldClassName,
      size = 'medium',
      width,
      iconLeft,
      iconRight,
      textWithIconRight,
      iconLeftClassName,
      iconButtonClassName,
      label,
      labelClassName,
      buttonText,
      primaryButtonText,
      copyButton,
      buttonClassName,
      buttonIcon,
      isFilled,
      errorMessage,
      placeholder,
      onChange,
      onButtonPress,
      inputExternalProps,
      buttonExternalProps,
      flag,
      inputClassName,
      mask,
      maskPlaceholder,
      checkLabel,
      checkMessage,
      buttonLoading,
      multiline,
      isMessageAbsolute = true,
      isDisabled = false,
      themeColor,
      borderRadius,
      secondButtonIcon,
      isFocusedStylesDisabled = false,
      ...props
    }: InputProps,
    ref,
  ) => {
    const { width: screenWidth } = useWindowSize();
    const [focused, setFocused] = useState(false);
    const [copied, setCopied] = useState(false);
    const { value } = props;

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e.target.value);
      }
      if (inputExternalProps?.onChange) {
        inputExternalProps.onChange(e);
      }
    };

    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setFocused(isFocusWithin),
    });

    const buttonRef = useRef<HTMLButtonElement>(null);
    const { buttonProps } = useButton(
      {
        elementType: 'button',
        isDisabled,
        onFocus: () => setFocused(true),
        onPress: (e) => {
          setFocused(false);
          if (onButtonPress) onButtonPress(e);
        },
      },
      buttonRef,
    );

    const handleFieldClick: DOMAttributes<HTMLDivElement>['onClick'] = (e) => {
      if (isDisabled) {
        e.stopPropagation();
        setFocused(true);
      }
    };

    function renderInput() {
      switch (true) {
        case !!mask: {
          return (
            <InputMask
              mask={mask as string}
              alwaysShowMask={false}
              maskPlaceholder={placeholder}
              placeholder={placeholder}
              type="text"
              {...mergeProps(props || {})}
              className={clsx(styles.input, inputClassName, { [styles.inputLarge]: size === 'large' })}
              readOnly={props.isReadOnly}
              disabled={isDisabled}
              aria-label="input"
              onChange={handleChange}
            />
          );
        }
        case multiline: {
          return (
            <textarea
              {...mergeProps(props, inputExternalProps || {})}
              className={clsx(styles.input, styles.textarea, inputClassName, { [styles.inputLarge]: size === 'large' })}
              readOnly={props.isReadOnly}
              disabled={isDisabled}
              placeholder={placeholder}
              aria-label="textarea"
              onChange={handleChange}
            />
          );
        }
        default: {
          return (
            <input
              {...mergeProps(props, inputExternalProps || {})}
              className={clsx(styles.input, inputClassName, { [styles.inputLarge]: size === 'large' })}
              ref={ref}
              readOnly={props.isReadOnly}
              disabled={isDisabled}
              placeholder={placeholder}
              aria-label="input"
              onChange={handleChange}
            />
          );
        }
      }
    }

    return (
      <>
        <div
          {...focusWithinProps}
          style={{ width }}
          className={clsx(styles.wrapper, styles[size], { [styles.disabled]: !!isDisabled }, className)}
        >
          {!!label && (
            <label
              className={clsx(styles.label, labelClassName, {
                [styles.labelDisabled]: !!isDisabled,
                [styles.labelLarge]: size === 'large',
              })}
            >
              {label}
            </label>
          )}
          <div
            onClick={handleFieldClick}
            style={{
              borderColor: themeColor && (value || isFilled || focused) ? themeColor : undefined,
              borderRadius: borderRadius ? `${borderRadius}px` : undefined,
            }}
            className={clsx(styles.inputField, inputFieldClassName, {
              [styles.isError]: errorMessage,
              [styles.filled]: !isFocusedStylesDisabled && (value || isFilled),
              [styles.disabled]: !!isDisabled,
              [styles.focused]: focused && !isFocusedStylesDisabled,
              [styles.inputFieldLarge]: size === 'large',
              [styles.inputFieldWithPrimaryButton]: primaryButtonText || copyButton,
            })}
          >
            {!!iconLeft && <Icon className={clsx(styles.icon, styles.iconLeft, iconLeftClassName)} name={iconLeft} />}
            {flag && flag}

            {renderInput()}

            {buttonText && (
              <button {...mergeProps(buttonProps, buttonExternalProps || {})} className={styles.button} ref={buttonRef}>
                <span
                  className={clsx(styles.buttonText, buttonClassName, {
                    [styles.disabled]: !!isDisabled,
                  })}
                >
                  {buttonText}
                </span>
              </button>
            )}
            {primaryButtonText && (
              <Button onPress={onButtonPress} loading={buttonLoading} variants="primary" size="small">
                {primaryButtonText}
              </Button>
            )}
            {copyButton && (
              <>
                {!copied && (
                  <Button
                    onPress={(e) => {
                      if (onButtonPress) {
                        onButtonPress(e);
                        setCopied(true);
                      }
                    }}
                    loading={buttonLoading}
                    variants="primary"
                    size={screenWidth > 1000 ? 'small' : 'mobile'}
                  >
                    Copy
                  </Button>
                )}
                {copied && (
                  <Label className={styles.copyButtonLabel} icon="check" size="medium" color="green">
                    Copied
                  </Label>
                )}
              </>
            )}
            {checkLabel && (
              <Label
                className={clsx(styles.copyButtonLabel, styles.checkLabel)}
                icon="check"
                size="medium"
                color="green"
              >
                Identified
              </Label>
            )}
            {buttonIcon && (
              <div className={styles.buttonIconWrapper}>
                {secondButtonIcon && <div className={styles.secondButtonIcon}>{secondButtonIcon}</div>}
                <button
                  {...mergeProps(buttonProps, buttonExternalProps || {})}
                  className={styles.button}
                  ref={buttonRef}
                >
                  <Icon
                    className={clsx(styles.icon, styles.buttonIcon, iconButtonClassName, {
                      [styles.buttonIconDisabled]: !!isDisabled,
                    })}
                    name={buttonIcon}
                  />
                </button>
              </div>
            )}
            {textWithIconRight && iconRight && (
              <>
                <FlagIcon countryName={iconRight} />
                <span className={styles.textWithIconRight}>{textWithIconRight}</span>
              </>
            )}
          </div>
          {!!errorMessage && isMessageAbsolute && (
            <div className={styles.error}>
              <p className={styles.errorText}>{errorMessage}</p>
            </div>
          )}
          {!!checkMessage && isMessageAbsolute && (
            <div className={styles.error}>
              <p className={styles.checkText}>{checkMessage}</p>
            </div>
          )}
        </div>

        {!!errorMessage && !isMessageAbsolute && (
          <div className={styles.error}>
            <p className={styles.errorText}>{errorMessage}</p>
          </div>
        )}

        {!!checkMessage && !isMessageAbsolute && (
          <div className={styles.error}>
            <p className={styles.checkText}>{checkMessage}</p>
          </div>
        )}
      </>
    );
  },
);

Input.displayName = 'Input';
