/* global axios */

import { get } from 'lodash';
import { CryptoCurrency, DocumentType, SearchPaginationFilterParams, PaymentType } from './common.types';

export const url = {
  authController: {
    createIndividualUser: `/user`,
    createUser: `/user/register`,
    initVoiceOTP: `/login/mobile/verification/init`,
    recallVoiceOTP: `/private/mobile/re-verification/init`,
    verifyVoiceOTP: `/login/mobile/verification/verify`,
    logIn: `/oauth/token`,
    logInStep1: `/oauth/get-code`,
    logInStep2: `/login/oauth/verify-code`,
    getCurrentUser: `/private/user`,
    getCurrentUserFull: `/office/customer/dashboard/user`,
    verifyEmailCode: (email: string, code: string) => `/user/email/${email}/verify/${code}`,
    resendVerifyEmailCode: (email: string) => `/user/email/${email}`,
    resendResetPasswordVerificationEmailCode: (email: string) => `/user/password-reset/email/${email}`,
    // changePasswordWithVerificationCodeForUnauthorizedUser: (email: string, code: string, sms: string) =>
    //   `/user/password-reset/email/${email}/verify/${code}/sms/${sms}`,

    // resendResetPasswordVerificationEmailCode: (email: string) => `/user/password-reset-request/email/${email}`,
    // changePasswordWithVerificationCodeForUnauthorizedUser: `/user/password-change-by-code`,
    verifyCodeForUnauthorizedUser: (email: string, code: string) =>
      `/user/password-reset/email/${email}/verify/${code}`,
    // changePasswordForAuthorizedUser: `/private/user/password-change-by-code`,
    changePasswordForAuthorizedUser: `/private/user/password`,
    sendVerificationCodeToAuthorisedUser: `/private/user/email/send-verification-code`,
    verifyDocument: (type: DocumentType) => `/private/business/document/${type}`,
    setMerchantUrl: (preferredUrl: string) => `/private/merchant/url/${preferredUrl}`,
    checkMfa: `/private/enabled/mfa`,
    sendMfaSms: `/login/mfa/sms`,
    generateQr: `/private/generate-qr`,
    activateQr: `/private/activate-qr`,
  },
  buyCryptoController: {
    getPrices: (
      fiat: string | undefined,
      crypto: string | undefined,
      payoutmethod: PaymentType | undefined,
      amount: string | number | undefined,
      network: string | undefined,
    ) => `/buy/quote/${fiat}/${crypto}/${network}/${amount}/${payoutmethod}`,
    getPrivatePrices: (
      fiat: string | undefined,
      crypto: string | undefined,
      payoutmethod: PaymentType | undefined,
      amount: string | number | undefined,
      network: string | undefined,
    ) => `/private/buy/quote/${fiat}/${crypto}/${network}/${amount}/${payoutmethod}`,
    getAmountsRange: (fiat: string = 'AUD', crypto: string, paymentType: PaymentType = PaymentType.NPP_BANK_TRANSFER) =>
      `/oracle/allowedamountrange/${fiat}/${crypto}/${paymentType}`,
    getPrivateAmountsRange: (
      fiat: string = 'AUD',
      crypto: string,
      paymentType: PaymentType = PaymentType.NPP_BANK_TRANSFER,
    ) => `/private/oracle/allowedamountrange/${fiat}/${crypto}/${paymentType}`,
    createTxn: `/private/buy/txs`,
    singleTxn: (id?: string) => `/private/buy/txs/${id}`,
    refundPayment: (id?: string) => `private/buy/txs/${id}/instructions/REFUND`,
    getWalletRegex: (cryptoCurrency?: string, cryptoNetwork?: string) =>
      `wallet/regexp/${cryptoCurrency}/${cryptoNetwork}`,
  },
  commonCryptoController: {
    convertFiatToCrypto: (
      crypto: string,
      amount: string,
      purpose: string,
      fiat: string = 'AUD',
      group: string = 'TRANSFER',
      network: string = 'ETH',
    ) => `/oracle/${purpose}/${fiat}/${crypto}${!!network ? `/${network}` : ''}/${amount}?group=${group}`,
    convertPrivateFiatToCrypto: (
      crypto: string,
      amount: string,
      purpose: string,
      fiat: string = 'AUD',
      group: string = 'TRANSFER',
      network: string = 'ETH',
    ) => `/private/oracle/${purpose}/${fiat}/${crypto}${!!network ? `/${network}` : ''}/${amount}?group=${group}`,
    verifyWallet: (cryptoNetwork: CryptoCurrency, address: string) =>
      `/private/merklescience/${cryptoNetwork}/${address}`,
    getCryptoCoins: '/crypto',
    getCoinNetworks: (cryptoCurrency: string) => `/network/${cryptoCurrency}`,
  },
  dashboardController: {
    dashboard: '/private/dashboard',
  },
  eCommerceController: {
    getEcommerceTxCryptos: (ecommerceTxRequestId: string) =>
      `/ecommerce/request/crypto/options/${ecommerceTxRequestId}`,
    getEcommerceTxRequest: (ecommerceTxRequestId: string) => `/ecommerce/request/${ecommerceTxRequestId}`,
  },
  fxController: {
    getRequiredFields: (country: string, fiat: string) => `/fx/availabledeliverymethods/${fiat}/${country}`,
    getAvailableCountriesList: (fiat: string) => `/fx/availabledeliverymethods/fiat/${fiat}`,
    getAvailableFiatList: () => `/fx/available-fiat-currencies`,
    getFxRate: '/private/merchant/fx-calculator',
  },
  hostedCommerceUiController: {
    getUiSettings: (merchantId?: string) => `/merchant/ui?merchantId=${merchantId}`,
    setMetaForUi: '/private/merchant/meta',
  },
  merchantCryptoController: {
    createTxn: `/merchant/txs`,
    singleTxn: (id?: string) => `/merchant/txs/${id}`,
    getHostedUrl: `/private/merchant/hosted/url`,
    checkCapabilities: `/private/merchant/capabilities`,
    getWebhookHistory: ({ page, size, sortField, isDesc }: SearchPaginationFilterParams) =>
      `/private/merchant/webhook-log/history?page=${page}&size=${size}&sort=${sortField},${isDesc ? 'desc' : 'asc'}`,
  },
  merchantDashboardController: {
    settlementInfo: `/private/merchant/settlement-information`,
    getAccessKeys: `/private/merchant/accesskeys`,
    createAccessKeys: `/private/merchant/accesskeys/create`,
    revokeAccessKeys: `/private/merchant/accesskeys/revoke`,
    downloadAccessKeys: `/private/merchant/accesskeys/download`,
  },
  onfidoController: {
    checkStatus: `/private/onfido/status`,
    getSdkToken: `/private/onfido/generate_sdk_token`,
    submitCheckStandard: (type?: string) => `/private/onfido/submit_check/${type}`,
    getCountries: `/onfido/countries`,
    checkAddress: '/private/onfido/check-address',
  },
  referralController: {
    getCodes: `/private/referral/codes`,
    getList: ({ page, size, sortField, isDesc }: SearchPaginationFilterParams) =>
      `/private/referral/earnings?page=${page}&size=${size}&sort=${sortField},${isDesc ? 'desc' : 'asc'}`,
    getTotal: `/private/referral/earnings/total`,
    sendToEmail: (email: string) => `/private/referral/invites/${email}`,
    cashout: `/private/referral/cashout`,
  },
  sellCryptoController: {
    createGiftCardTxn: `/private/giftcard/txs`,
    validateBsb: (bsb: string = '') => `/validateBsb/instant/${bsb}`,
    validateBpay: (bpay: string = '') => `/validateBpay/instant/${bpay}`,
    createTxn: `/private/sell/txs`,
    singleTxn: (id?: string) => `/private/sell/txs/${id}`,
    getPrices: (
      fiat: string | undefined,
      crypto: string | undefined,
      network: string | undefined,
      payoutmethod: PaymentType | undefined,
      amount: string | number | undefined,
    ) => `/sell/quote/${crypto}/${network}/${fiat}/${amount}/${payoutmethod}`,
  },
  transactionsController: {
    getBankTxs: ({ page, size, sortField, isDesc }: SearchPaginationFilterParams) =>
      `/private/sell/txs/bank?page=${page}&size=${size}&sort=${sortField},${isDesc ? 'desc' : 'asc'}`,
    getBankTxsCsv: `/private/sell/txs/bank/csv`,
    getBillTxs: ({ page, size, sortField, isDesc }: SearchPaginationFilterParams) =>
      `/private/sell/txs/bill?page=${page}&size=${size}&sort=${sortField},${isDesc ? 'desc' : 'asc'}`,
    getBillTxsCsv: `/private/sell/txs/bill/csv`,
    getBuyCryptoTxs: ({ page, size, sortField, isDesc }: SearchPaginationFilterParams) =>
      `/private/buy/txs?page=${page}&size=${size}&sort=${sortField},${isDesc ? 'desc' : 'asc'}`,
    getBuyCryptoTxsCsv: `/private/buy/txs/csv`,
    getMerchantPayments: ({ page, size, sortField, isDesc }: SearchPaginationFilterParams) =>
      `/private/merchant/transaction/history?page=${page}&size=${size}&sort=${sortField},${isDesc ? 'desc' : 'asc'}`,
    getMerchantPaymentsCsv: `/private/merchant/transaction/csv`,
  },
  userController: {
    updateBusinessUserInfo: `/login/user/register`,
    updateBusinessDetails: `/private/user/register/business-details`,
    address: `/private/user/address`,
  },
  kybController: {
    checkStatus: `/private/kyc/status`,
    submitKYB: `private/kyb/submit`,
  },
  termsController: '/private/user/terms',
  addressBookController: {
    depositWallet: (cryptoCurrency: CryptoCurrency, cryptoNetwork: CryptoCurrency) =>
      `/private/addressbook/deposiwallet/${cryptoCurrency}/${cryptoNetwork}`,
    bankDetails: (paymentType: PaymentType) => `/private/addressbook/bankdetails/${paymentType}`,
  },
  reviewController: {
    whereDidYouHear: '/login/review/where-did-you-hear-about-us',
    rating: '/login/rating',
    getRating: '/private/rating',
  },
  priceController: {
    getPrices: (crypto: string, fiat: string, amount: string, parent?: string) =>
      `/merchant/price/${crypto}/${fiat}/${amount}${parent ? `?parent=${parent}` : ''}`,
  },
};

export const PRIVATE_URLS = ['/admin/', '/office/', '/private/', '/login/', 'private/'];
